import { BrowserRouter } from "react-router-dom";
import MainRouterApp from "./_routes/MainRouterApp";
interface Props {
	
}

const App = (props: Props) => {
	return (
		<BrowserRouter>
			<MainRouterApp />
		</BrowserRouter>
	)
}

export default App