import  { createContext, ReactNode } from 'react'
import useSocketIO from '../_hooks/useSocketIO';

interface Props {
	children:ReactNode
}

export const SocketContext = createContext<{
	socket: SocketIOClient.Socket | null ,
	online: boolean 
} > ({
	socket:null,
	online:false
});
const SocketProvider = ({children}: Props) => {
	// http://tracker.grupoamancio.com:3001
	const {socket,online} = useSocketIO({url:""});
	// const {socket,online} = useSocketIO({url:"http://tracker.allsmartt.com:3001"});
	// const {socket,online} = useSocketIO({url:"http://localhost:3001"});

	return (
		<SocketContext.Provider value={{
			socket,
			online
		}}>
			{children}
		</SocketContext.Provider>
	)

}

export default SocketProvider
