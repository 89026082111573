import {  useEffect } from 'react'
import FooterApp from './FooterApp'
import { Navigate, Outlet } from "react-router-dom";
import './trackApp.scss'
import VhPanel from './rpanel/VhPanel';
import { useAppDispatch } from '../../_store/hooks';
import { hideVHPanel, setLoadingAction } from '../../_store/actions/uiActions';
import useValidSession from '../../_hooks/useValidSession';
import { loginAction } from '../../_store/actions/authActions';
import { UserT } from '../../_types/userType';
import { securedFetch } from '../../_helpers/fetchHelper';
import { setFleetInfoAction, setVhListAction } from '../../_store/actions/trackActions';

interface Props {
	
}
const TrackApp = (props: Props) => {
	console.log("  RENDER:  TrackApp!!!");

	const {valid, session}= useValidSession();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (valid) {
			session && dispatch(loginAction(session as UserT))
			
			// Cargar la Flotilla y los vehiculos
			dispatch(setLoadingAction(true));
			securedFetch(
				'/mtrack/fleet','POST',{
					action: 'getCurrenFleet&List',
				}
			).then(responce=>{
				if (!responce.error) {
					if (responce.data.status === 'ok') {
						dispatch(setFleetInfoAction(responce.data.data.fleet));
						if (responce.data.data.vehicles ) {
							dispatch(setVhListAction(responce.data.data.vehicles || []));
						}
					}
				}
				setTimeout(() => {
					dispatch(setLoadingAction(false));
				}, 1000);
			});
			// ********************
		}
		return () => {

		}
	}, [])
	if (!valid) {
		return <Navigate to='/login' />	
	}

	return (
		<>
			<div 
				className="fullContainer"
				onClick={()=>{
					dispatch(hideVHPanel())
				}}
			>
				<section className="fchild">
					<Outlet />
				</section>
				<FooterApp />
			</div>
			<VhPanel />
		</>
	)
}

export default TrackApp
