import React from 'react'
import { useAppSelector } from '../../../_store/hooks'

interface Props {
	
}

const FleetInfo = React.memo ((props: Props) => {
	console.log("  RENDER: FleetInfo");

	const vehListLen = useAppSelector(state => state.track?.vehicles?.length)
	const fleet = useAppSelector(state => state.track?.fleet)
	return (
		<div className="fleet-block">
			<div className="fleetname is-flex">
				<div className="fleesbname fleetcompany">
					{fleet?.companititle}
				</div>
				<div className="fleesbname">
					{fleet?.name}
				</div>
			</div>
			<div className="fleetcount is-flex">
				{vehListLen}
			</div>
			<div className="fleetColor is-flex" style={{
				backgroundColor: fleet?.color?.toString()
			}}>
			</div>
		</div>
	)
})

export default FleetInfo
