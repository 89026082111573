import React from 'react'

interface Props {
	
}

const ConfigScreen = (props: Props) => {
	return (
		<div>
			<div className="title is-3">Configuracion</div>
		</div>
	)
}

export default ConfigScreen
