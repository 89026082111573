// import {  } from "socket.io-client";
import { useEffect, useMemo, useState } from 'react';
import * as io from 'socket.io-client';
import { securedFetch } from '../_helpers/fetchHelper';
import { ProcessTransmission } from '../_helpers/gpsDefragHelper';
import { newTransmision } from '../_store/actions/trackActions';
import { setRecofinishedAction } from '../_store/actions/uiActions';
import { useAppDispatch, useAppSelector } from '../_store/hooks';

interface Props {
	url:string
}

const _token  = localStorage.getItem('APPTOKEN');
const useSocketIO = ({url}: Props):{
	socket: SocketIOClient.Socket
	online: boolean
} => {
	const [online, setOnline] = useState(false);

	const socket = useMemo(() => io.connect(url, {
		query: {
			token: _token
		},
		reconnection: true,
		reconnectionDelay: 1000,
		reconnectionDelayMax: 5000,
		reconnectionAttempts: 99999,
		transports: ['websocket']
	}), [url] );
	

	const dispatch = useAppDispatch();
	const leveOne = useAppSelector(state => state.track?.lastunsuscribe);
	const subsOne = useAppSelector(state => state.track?.lastSuscribe);
	
	// Pendiente en el cambio de las subscripciones
	useEffect(() => {
		setTimeout(() => {
			const trackList: string[] = JSON.parse(localStorage.getItem('trackList') || '[]') || [];	
			let suma = 0
			if (socket && online === true ) {
				trackList.forEach(v=>{
					socket?.emit('subVehOne', v);
					suma++;
				});
			}
		}, 4500);
	}, [socket, online]);

	// se abandona un vehiculo
	useEffect(() => {
		leveOne && online && socket && socket.emit('leveVehOne',leveOne)
	}, [leveOne, socket, online])

	useEffect(() => {
		subsOne && online && socket && socket.emit('subVehOne',subsOne)
	}, [subsOne, socket, online])

	

	// Se obtuvo un ultimo estado!
	useEffect(() => {
		socket?.on("laststs",(data:any)=>{
			console.log(data, "<<<<< Last Status !!!!! ");
		})
		return () => {socket?.off("laststs")};
	}, [socket]);
	
	// Ultima Trasnmision
	useEffect(() => {
		socket?.on("transmision",(data:any)=>{
			const transmision = ProcessTransmission(data);
			console.log(data,transmision, "<< Lo recibido");
			
			if (transmision) {
				dispatch(newTransmision(transmision))
			}
		})
		return () => {socket?.off("transmision")};
	}, [socket]);

	// Obtener Mensages
	useEffect(() => {
		socket?.on("message",(data:any)=>{
			console.log("Message from Server>>>", data);
			
		})
		return () => {socket?.off("message")};
	}, [socket]);

	// Obtener Mensages
	useEffect(() => {
		socket?.on("travelOk",(data:any)=>{
			dispatch(setRecofinishedAction(data.id));
			// console.log( data, "TRAVEL Ok!>>>","🔴🔴🔴🔴🔴");
		})
		return () => {socket?.off("travelOk")};
	}, [socket]);
	
	
	// manejo de eventos
	useEffect(() => {
		socket.on('connect',()=>{
			setOnline(true);
		})
	}, [socket]);

	useEffect(() => {
		socket.on('disconnect',()=>{
			console.log("Se Desconecta");
			
			setOnline(false);
		})
	}, [socket])



	return {
		socket,
		online
	};



}

export default useSocketIO
