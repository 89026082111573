import React, { ChangeEvent, useRef, useState } from 'react'
import { asyncLogoutAccion } from '../../../_store/actions/authActions';
import { useAppDispatch, useAppSelector } from '../../../_store/hooks';
import VHListItem from '../VehicleItem/VHListItem';
import FleetInfo from './FleetInfo';

interface Props {
	
}

const VHPBody = React.memo((props: Props) => {
	console.log("  RENDER: VHPanel Body");

	const [filtro, setFiltro] = useState('');
	const [filterString, setFilterString] = useState('')
	const time = useRef<any>(null)

	const dispatch = useAppDispatch();
	const vehList = useAppSelector(state => state.track?.vehicles);
	const isLoading = useAppSelector(state => state.ui?.dataLoading)
	
	const onCloseBuetton= async  ()=>{
		dispatch(await asyncLogoutAccion())
	};

	const handleProps = ({target} : ChangeEvent<HTMLInputElement>)=>{
		setFilterString(target.value);
		if (time.current) {
			clearTimeout(time.current);
		}
		time.current = setTimeout(()=>{
			setFiltro(target.value);
			target.select()
		}, 800);
	}

	if (isLoading===true) {
		return	<>
					<div className="title is-5">Loading</div>
					<progress className="progress is-small is-warning" max="100">Loading...</progress>
				</>
	}
	return (
		<>
			<div className="logo">
					<img  className='logoImage' src="/assets/images/logo250.png" alt="AllSmartt.com"/>	
			</div>
			<FleetInfo />
			<div className="fleet-block">
				<div className="control has-icons-right" style={{
					width: "100%"
				}}>
					<input className='input is-small searcinput' type="text" value={filterString} onChange={ handleProps } />	
					<span className='icon is-small is-right'>
						<i className='as-search'></i>
					</span>
				</div>
			</div>
			<div className="vehList" >
				<ul className="vhList">
					{
						vehList?.filter(vh=>{
							if (filtro && filtro.length > 0) {
								const pajar = `${vh.alias}:${vh.plate}:${vh.name}:${vh.imei}:${vh._id}`
								return pajar.toUpperCase().includes(filtro.trim().toUpperCase());
							}
							return true;

						}).map(vh=>{
							return <VHListItem key={vh._id} vehicle={vh} />
						})
					}

				</ul>
			</div>
			<button onClick={onCloseBuetton} className="button is-warning logoutbtn">
				Cerrar Session
			</button>	
		</>
	)
});

export default VHPBody
