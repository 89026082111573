import { FleetT } from "../../_types/fleetType";
import { POSTransmision } from "../../_types/gpsComunicatorType";
import { vehicleRT_T } from "../../_types/vehicleRTType";
import { trackActionsList, TrackActionsT } from "../actions/trackActions";


// definicion del 
export type TrackStateI = {
	fleet?: FleetT | null,
	vehicles?: vehicleRT_T[],
	subscribers: string[],
	lastunsuscribe?: string | null,
	lastSuscribe?: string | null,
	lastPos?: POSTransmision | null,
	setCenter?:[number, number]
}

const _INIT_STATE = {
	fleet: null,
	vehicles: [],
	subscribers: [],
	lastunsuscribe: null,
	lastPos: null,
	lastSuscribe: null,

}

export const TRACKReducer = (state: TrackStateI = _INIT_STATE, action: TrackActionsT): TrackStateI | undefined => {
	switch (action.type) {
		case trackActionsList.setFleetInfo:
			return {
				...state,
				fleet: action.payload
			}
		case trackActionsList.setVehiclesList:
			const trackList: string[] = JSON.parse(localStorage.getItem('trackList') || '[]') || [];

			return {
				...state,
				vehicles: [...action.payload.map((v: vehicleRT_T) => {
					return {
						...v,
						follow: false,
						current: false,
						tracking: trackList.indexOf(v._id) > -1,
					}
				}).sort((a, b) => {
					if (a.tracking && !b.tracking) {
						return -1
					} else if (!a.tracking && b.tracking) {
						return 1;
					} else {
						if ((a.speed || 0) > (b.speed || 0)) {
							return -1
						} else {
							return 0;
						}
					}
				})
				],
				subscribers: trackList
			}
		case trackActionsList.setTrackingVh: {
			if (action.payload.state === true) {
				const count = state.vehicles?.filter(x => x.tracking === true);
				if ((count?.length || 0) > 10) {
					return state;
				}
			}
			const trackList: string[] = JSON.parse(localStorage.getItem('trackList') || '[]') || [];
			let lastdel = null;
			let lastsub = null;

			return {
				...state,
				vehicles: [...(state.vehicles || [])].map(vh => {
					if (vh._id === action.payload.vh) {
						if (action.payload.state === true) {
							trackList.push(action.payload.vh);
							lastsub = action.payload.vh;
						} else {
							const idx = trackList.indexOf(action.payload.vh);
							if (idx > -1) {
								trackList.splice(idx, 1);
								lastdel = action.payload.vh;
							}
						}
						localStorage.setItem('trackList', JSON.stringify(trackList))

						return {
							...vh,
							tracking: action.payload.state,
						}
					} else {
						return vh;
					}
				}).sort((a, b) => {
					if (a.tracking && !b.tracking) {
						return -1
					} else if (!a.tracking && b.tracking) {
						return 1;
					} else {
						if ((a.speed || 0) > (b.speed || 0)) {
							return -1
						} else {
							return 0;
						}
					}
				}),
				subscribers: trackList,
				lastunsuscribe: lastdel,
				lastSuscribe: lastsub

			}
		}
		case trackActionsList.setCurrentVh: {
			return {
				...state,
				vehicles: [...(state.vehicles || [])].map(vh => {
					if (vh._id === action.payload.vh) {
						return {
							...vh,
							current: action.payload.state
						}
					} else {
						if (vh.current === true) {
							return {
								...vh,
								current: false
							}
						}
						return vh;
					}
				})
			}
		}
		case trackActionsList.setCenter: {
			return {
				...state,
				setCenter: action.payload
			}
		}
		case trackActionsList.setFollowVh:
			return {
				...state,
				vehicles: [...(state.vehicles || [])].map(vh => {
					if (vh._id === action.payload.vh) {
						return {
							...vh,
							follow: action.payload.state
						}
					} else {
						if (vh.follow === true) {
							return {
								...vh,
								follow: false
							}
						}
						return vh;
					}
				})
			}
		case trackActionsList.sortVh:
			return {
				...state,
				vehicles: [...(state.vehicles || [])].sort((a, b) => {
					if (a.tracking && !b.tracking) {
						return -1
					} else if (!a.tracking && b.tracking) {
						return 1;
					} else {
						return 0;
					}
				})
			}
		case trackActionsList.newTransmision: {
			return {
				...state,
				vehicles: [...(state.vehicles || [])].map(v => {
					if (v._id === action.payload._id) {
						return {
							...v,
							azimut: action.payload.azimut,
							online: action.payload.isOnline,
							latitud: action.payload.latitud,
							longitud: action.payload.longitud,
							speed: action.payload.speed,
							stoptime: action.payload.stoptime,
							motor: action.payload.stsmotor,
							svrtime: action.payload.tgps,
						}
					} else {
						return v;
					}
				})
			}
			break;
		}
		default:
			break;
	}

	if (state) {
		return state;
	} else {
		return undefined;
	}
}



