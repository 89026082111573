import { _SERVER_ } from "./enviroment";
// import { Selector } from "react-redux";
import Swal from "sweetalert2";  
import { logoutAction } from "../_store/actions/authActions";


type fetchStateT = {
	data: any,
	error: any
}
interface Props {
	options?:  {
		/** A BodyInit object or null to set request's body. */
		body?: BodyInit | null;
		/** A string indicating how the request will interact with the browser's cache to set request's cache. */
		cache?: RequestCache;
		/** A string indicating whether credentials will be sent with the request always, never, or only when sent to a same-origin URL. Sets request's credentials. */
		credentials?: RequestCredentials;
		/** A Headers object, an object liter[key:string]:anyal, or an array of two-item arrays to set request's headers. */
		headers?: HeadersInit;
		/** A cryptographic hash of the resource to be fetched by request. Sets request's integrity. */
		integrity?: string;
		/** A boolean to set request's keepalive. */
		keepalive?: boolean;
		/** A string to set request's method. */
		method?: string;
		/** A string to indicate whether the request will use CORS, or will be restricted to same-origin URLs. Sets request's mode. */
		mode?: RequestMode;
		/** A string indicating whether request follows redirects, results in an error upon encountering a redirect, or returns the redirect (in an opaque fashion). Sets request's redirect. */
		redirect?: RequestRedirect;
		/** A string whose value is a same-origin URL, "about:client", or the empty string, to set request's referrer. */
		referrer?: string;
		/** A referrer policy to set request's referrerPolicy. */
		referrerPolicy?: ReferrerPolicy;
		/** An AbortSignal to set request's signal. */
		signal?: AbortSignal | null;
		/** Can only be null. Used to disassociate request from any Window. */
		window?: null;
	}
}

export const runFetch = 
async  (
	url:string,
	method:'GET'|'POST'|'PUT'|'DELETE' | null ='GET', 
	body:{[key:string]: any} | null | undefined, 
	{options}:Props={}
):Promise<fetchStateT>=>{
	
	if (url) {
		const _url = _SERVER_+url;
		options = options || {};
		
		if (!options.hasOwnProperty('method')) {
			options.method =  method ? method : 'GET'
		}
		if (options.method === 'POST'   ) {
			if (!options.headers || !options.headers.hasOwnProperty('Content-Type') ) {
				options.headers = Object.assign((options.headers || {}), {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				});
			}

			if (body && !options.hasOwnProperty('body')) {
				options.body = JSON.stringify(body);
			}

		}
		options['mode'] = 'cors';

		let resp:fetchStateT = {
			data: null,
			error: null
		}
		await new Promise((resolve, reject) => {
			fetch(_url,options || {}).then(resp=>resp.json()).then((data:any)=>{	
				resp.data = {...data};
				console.log(data, "<< Respuesta ORIGINAL")
				if (['error', 'fail', 'err'].indexOf(data?.status) > -1) {

				}

				if (data?.hasOwnProperty('msg')) {
					console.info(data.msg);
				}
				resolve(null);
			})
			.catch((r)=>{
				resp.data = r;
				resolve(null);
			})
		})

		return resp;
	}

	return {
		data: null,
		error: 'La URL no esta definida'
	};
}


export const securedFetch = async  (
	url:string,
	method:'GET'|'POST'|'PUT'|'DELETE' | null ='GET', 
	body:{[key:string]: any} | null | undefined, 
	{options}:Props={})
:Promise<fetchStateT>=>{
	// return runFetch(url, method,body,options as Props);

	if (url) {
		options = options || {};
		if (!options.hasOwnProperty('method')) {
			options.method =  method ? method : 'GET'
		}
		if (options.method === 'POST'  || options.method === 'PUT'  ) {
			if (!options.headers || !options.headers.hasOwnProperty('Content-Type') ) {
				options.headers = Object.assign((options.headers || {}), {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				});
			}

			if (body && !options.hasOwnProperty('body')) {
				options.body = JSON.stringify(body);
			}
		}
		options['mode'] = 'cors';
		const token = localStorage.getItem('APPTOKEN');

		// recuperar Token
		
		if (token) {
			options.headers = Object.assign((options.headers || {}),  {"Authorization" : `Bearer ${token}`});
			return new Promise((resolve, reject)=>{
				runFetch(url, method,body,{options} as Props).then(x=>{
					console.log(x, "<< Respuesta en el INTERCEPTOR");
					if (x.data && x.data.err && x.data.err === 'Invalid Token' ) {
						console.log("DESTRUIR TOKEN!! 🔴 🔴 🔴 🔴");
						localStorage.removeItem('APPTOKEN');
						window.location.reload()
					}
					if(x.data && x.data.status &&  ['err', 'error', 'fail'].indexOf(x.data.status) > -1){
						const text = x.data.msg || x.data.message;
						if (text) {
							Swal.fire({  
								icon: 'error',  
								title: 'BackendError',  
								text: text,  
								footer: 'allSmartt Rules!'  
							});
						}
						console.log(x.data, "<<<<Error Capturado en el interceptor")
					}
					resolve(x);
				}).catch(err=>{
					resolve(err);
				})
			});
		}
		let resp:fetchStateT = {
			data: null,
			error: 'Invalid Token!'
		}
		return resp;
	}

	return {
		data: null,
		error: 'La URL no esta definida'
	};
}