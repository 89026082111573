import React, { ReactNode, useState, } from "react";
import {  
	GoogleMap,
	withScriptjs,
	withGoogleMap,
} from "react-google-maps";
import {dark_map, sandmap, as_map} from "../../../_helpers/mapstyle";
import "./gmap.scss"


// declare let google: any;

interface Props {
	center: {
		lat: number,
		lng: number
	},
	zoom: number,
	typeMap:string,
	color:string,
	setZoom: (val:number)=>void
	children?:ReactNode[]
	setBoundary?: (val:google.maps.LatLngBounds)=>void
	refMap?: React.Dispatch<React.SetStateAction<GoogleMap | null>>
	
}
let centerTimer:any =  null;
let zoomTimer:any =  null;

let  ref : GoogleMap


const GMap = React.memo(withScriptjs(
	withGoogleMap(
		({center, zoom, typeMap, color, children, setZoom, refMap, setBoundary }: Props) => {
			return (<>
				{
					console.log('RENDER MAP 🗺️')
				}
				<GoogleMap
					defaultZoom={zoom}
					ref={(mapRef) => {
						if (mapRef) {
							refMap&&refMap(mapRef)
							ref = mapRef!;
						}
					}}
					defaultCenter={center}
					defaultOptions={{
						zoomControl:false,
						mapTypeControl:true,
						mapTypeControlOptions:{
						},
						styles: [[], dark_map, sandmap, as_map ][Number(color) || 0] as any
						// zoomControl:false,
					}}
					onCenterChanged= {()=>{
						// console.log(ref.getCenter().toJSON());
						centerTimer && clearTimeout(centerTimer)
						centerTimer = setTimeout(() => {
							try {
								localStorage.setItem('mapCenter',JSON.stringify(ref.getCenter().toJSON()));							
							} catch (error) {}
						}, 600);
					}}
					onBoundsChanged ={()=>{
						setBoundary && setBoundary(ref.getBounds())

					} }

					center = {
						center
					}
					onZoomChanged ={
						()=>{
							setZoom(ref.getZoom());
							
							zoomTimer && clearTimeout(zoomTimer);
							zoomTimer = setTimeout(()=>{
								try {
									localStorage.setItem('mapZoom', ref.getZoom().toString())
								} catch (error) {}
							}, 600)	
						}
					}
					// defaultMapTypeId={google ? 'dark': 'satellite'}
					mapTypeId= {typeMap}
				>
					{children}
				</GoogleMap>
			</>);
		}
		
	)
));


export default GMap; 
