import  { FormEvent, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import { securedFetch } from '../../_helpers/fetchHelper';
import { useForm } from '../../_hooks/useForm';
import useValidSession from '../../_hooks/useValidSession';
import { useAppDispatch, useAppSelector } from '../../_store/hooks';
import TopLogo from '../componens/TopLogo';
import Swal from "sweetalert2";  
import { setRecofinishedAction, showVHPanelAction } from '../../_store/actions/uiActions';
import { SocketContext } from '../../context/SocketContext';
import TravelScreen from './travelScreen';

interface Props {
	
}
interface FormDataI{
	fecha:Date,
	finit: string,
	ffin: string,
}
const getTravel =  (id : string)=>{
	return new Promise<string | void>((resolve, reject) => {
		securedFetch('/mtrack/travels','POST',{
				action:"getTravel",
				refval:id
			}
		).then(responce=>{
			console.log(responce.data, "<<");
			if (!responce.error) {
				if (responce.data.status === 'ok') {
					console.log(responce.data, "<<< FFF");
					
					switch (responce.data.data.state) {
						case 'finish':
							localStorage.setItem('reco', JSON.stringify(responce.data.data.info));
							localStorage.removeItem('TRAVELNAME');
							resolve('ok')
							break;
						case 'process':
							resolve('process')
							break
						default:
							break;
					}
					return;
				}else{
					localStorage.removeItem('TRAVELNAME');
					localStorage.removeItem('reco');
					reject('no existe')
				}
			}else{
				reject('procesing error')
			}
		});
	});
}

const checkTravel = (id:string)=>{
	return new Promise<void>((resolve, reject) => {
		securedFetch('/mtrack/travels','POST',{
				action:"checkTravel",
				refval:id
			}
		).then(responce=>{
			if (!responce.error) {
				if (responce.data.status === "ok") {
					resolve();
				}
				reject()
			}else{
				reject()
			}
		});
	})
}


const ReplayScreen 	= (props: Props) => {
	const TRAVELNAME = localStorage.getItem("TRAVELNAME");
	const RECO = localStorage.getItem("reco");

	const finish = useAppSelector(state=>state.ui?.recofinished);

	const navigate 	= useNavigate();
	const dispatch 	= useAppDispatch();

	const {socket, online}		= useContext(SocketContext)
	const vehicle				= useAppSelector(state=>state.ui?.vehReplayFor);
	const {valid}				= useValidSession();
	const [reload, setReload]	= useState(0);
	const getTravelInterval		= useRef<NodeJS.Timeout | null>(null)
	const ref = useRef<NodeJS.Timeout | null>(null)
	
	
	useEffect(() => {
		if (TRAVELNAME) {
			checkTravel(TRAVELNAME).then(()=>{
				socket?.emit('joinRoom',{
					room: TRAVELNAME
				});
				dispatch(setRecofinishedAction(TRAVELNAME));
			}).catch(r=>{
				localStorage.removeItem('TRAVELNAME');
			});

		}
	}, [socket]);
	useEffect(()=>{
		if (finish) {
			getTravelInterval.current && clearInterval(getTravelInterval.current);
			getTravelInterval.current = setInterval(()=>{
				getTravel(finish).then((r)=>{
					console.log(r, "<<< RESPUESTA a 😅 😅 😅 😅");
					if (r === 'ok') {
						dispatch(setRecofinishedAction(undefined));
						getTravelInterval.current && clearInterval(getTravelInterval.current);
						setReload(x=>x+1);
					}
					if (r === 'no existe') {
						getTravelInterval.current && clearInterval(getTravelInterval.current);
					}
				}).catch(r=>{
					if (r === 'no existe') {
						getTravelInterval.current && clearInterval(getTravelInterval.current);
					}
				});
			},2500);
		} 
	},[finish]);

	// dispatch(setRecofinishedAction(data.id));

	const { state:frmdata, handlerInputChange, setDataForm } =useForm<FormDataI>({
		fecha: new Date(new Date().setHours(12,1,0)),
		finit: '05:00:00',
		ffin: new Date().toTimeString().split(' ')[0]
	});


	if (RECO) {
		return <TravelScreen />
	}

	const {fecha, finit, ffin }= frmdata;


	if (!valid) {
		return <Navigate to={'/login'} replace= {true} />;
	}
	const submit =async (e:FormEvent<HTMLFormElement> | null)=>{
		if (!vehicle) {
			Swal.fire({  
				icon: 'error',  
				title: 'ASPlatform',  
				text: 'Debe seleccionar primero un vehivulo, del panel de vehiculos en la pantalla de mapa.',  
				footer: 'allSmartt'  
			  });
			return;
		}
		e && e.preventDefault();
		let _nfecha = fecha;
		if (typeof fecha === "string") {
			_nfecha =new Date(fecha);
		}
		let body = {
			fecha: fecha,
			finit: new Date(new Date(`${_nfecha.toISOString().split('T')[0]}T${finit}`).getTime()  ),
			ffin: new Date(new Date(`${ _nfecha.toISOString().split('T')[0]}T${ffin}`).getTime()  ),
			alias: vehicle.alias,
			vehicle: vehicle._id
		}
		
		securedFetch('/mtrack/travels','PUT',body,{
				options:{
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					}
				}
			}
		).then(responce=>{
			console.log(responce.data, "<<");
			if (!responce.error) {
				if (responce.data.status === 'ok') {
					try {
						socket && socket.emit('joinRoom',{
							room: responce.data.data
						});
						localStorage.setItem('TRAVELNAME',responce.data.data.toString());
						dispatch(setRecofinishedAction(responce.data.data.toString()));
					} catch (error) {
						console.log(error, "<< JOIN ROOM!");
					} 
					Swal.fire({  
						icon: 'info',  
						title: 'ASPlatform',  
						text: responce.data.msg || "Espere....",  
						titleText: 'allSmartt' ,
						timer: 4500
					});
				};
			}
		});
	}


	return (
		<>
			< TopLogo />
			<div id="replayConf">
				<br />
				<br />
				<br />
				<br />
				<h1 className="title is-6 box">Recorridos.</h1>
				<form className="box" onSubmit={submit}>
					<table className='formTable '>
						<tbody>
							<tr>
								<td>Vehiculo: </td>
								<td>
									<input 
										onClick={()=>{
											setTimeout(() => {
												dispatch(showVHPanelAction());
											}, 50);
										}}
										className="input" 
										type="text" 
										value={(vehicle?.alias || '') +'  :  '+ (vehicle?.plate || '') }
										placeholder="debe seleccionar un vehiculo." 
										readOnly = {true}>
									</input>
								</td>
							</tr>
							<tr>
								<td>Fecha:</td>
								<td>
									<input 
										className="input" 
										type="date" 
										autoComplete='off'
										name='fecha'
										value={new Date(fecha).toISOString().split('T')[0]}
										onChange={handlerInputChange}
										placeholder="Seleccione Fecha." 
									/>
								</td>
							</tr>
							<tr>
								<td>Inicio:</td>
								<td>
									<input 
										className="input" 
										type="time"
										name='finit'
										placeholder="Seleccione Hora inicial."
										value={finit}
										onChange={handlerInputChange}
									/>
								</td>
							</tr>
							<tr>
								<td>Fin:</td>
								<td>
									<input 
										className="input" 
										type="time"
										name='ffin'
										placeholder="Hora Final."
										value={ffin}
										onChange={handlerInputChange}
									/>
								</td>
							</tr>
						</tbody>
					</table>
					<br />
				</form>
				<button
				onClick={()=>submit(null)} 
					className="button is-success is-fullwidth"
				>Generar</button>

				{/* <div className="queryForm">
				</div> */}
			</div>
		</>
	)
}

export default ReplayScreen
