import { runFetch } from "../../_helpers/fetchHelper";
import { UserT } from "../../_types/userType";
import { isExpired, decodeToken } from "react-jwt";
import Swal from "sweetalert2";  

//Todas las posibles acciones del auth reducer 
export enum authActionList {
	login='[AUTH] Login',
	logout='[AUTH] LogOut',
	setToken='[AUTH] SetToken',
}

// firma de las acciones del Auth Reducer
export type AuthAction =
  | { type: authActionList.login, payload: UserT | null }
  | { type: authActionList.setToken, payload: string }
  | { type: authActionList.logout }

export const loginAction = (user:UserT|null):AuthAction=>({
	type: authActionList.login,
	payload: user
});

export const setTokenAction = (token:string):AuthAction=>({
	type: authActionList.setToken,
	payload: token
});



export const asyncLoginAction = async (email:string, password:string)=>{	
	return async (_dispatch:any)=>{
		try {
			const {data} = await runFetch('/mtrack/mlogin','POST',
			{
				email:email,
				password:password
			});
			
			data.error && console.log(data.error);
			if (data.data) {
				
				if(data.data.type === 'ok'){
					const payload = decodeToken(data.data.token);
					if (!isExpired(data.data.token)) {
						localStorage.setItem('APPTOKEN', data.data.token);
						_dispatch(loginAction(payload as UserT));
						_dispatch(setTokenAction(data.data.token));
					}
				}else{
					Swal.fire({
						icon:'error',
						title:"Login",
						text: "Error de autenticacion",
						timer: 3800
					});
				}
			}
		} catch (error) {
			console.log(error, "<< aqui en asyncLoginAction");
			
		}
	}
}
export const logoutAction = ():AuthAction=>({
	type: authActionList.logout
});

export const asyncLogoutAccion = async ()=>{
	return async (dispatch:any)=>{
		localStorage.removeItem('APPTOKEN');
		dispatch(logoutAction());
	}	
}