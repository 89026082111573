import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { authReducer, AuthStateT } from './reducers/authReducer';
import { TRACKReducer, TrackStateI } from './reducers/trackReducer';
import { UIReducer, UIStateI } from './reducers/uiReducer';

export type GlobalStateI = {
	auth: AuthStateT | null,
	ui: UIStateI | null,
	track: TrackStateI | null,
}


export const globalStore = configureStore({
  reducer: {
    auth: authReducer,
	ui: UIReducer,
	track: TRACKReducer,
  },
});



// Typo Global del Dispach
export type AppDispatchI = typeof globalStore.dispatch

export type AppDispatchT = typeof globalStore.dispatch;
export type AppStateI = ReturnType<typeof globalStore.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppStateI,
  unknown,
  Action<string>
>;

try {
	typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__();
} catch (error) {
}
