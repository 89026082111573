import React from "react";
import { NavLink } from "react-router-dom";
interface Props {
	
}

const FooterApp = React.memo ((props: Props) => {
	console.log("  RENDER: FooterApp");

	return (
		<>
			<footer className="box fchild ffooter ">
				<div className="columns is-mobile is-fullh">
					<NavLink 
						to='map'
						className={({isActive})=>`column bottonIcon navbar-item ${isActive && 'ACTIVELINK'} `}
					>
						<i className="icon as-globe"></i>
						<span className="labelRt">Track</span>
					</NavLink>

					<NavLink 
						to='replay'
						className={({isActive})=>`column bottonIcon navbar-item ${isActive && 'ACTIVELINK'} `}
					>
						<i className="icon as-map"></i>
						<span className="labelRt">Replay</span>
					</NavLink>

					<NavLink 
						to='usrinfo'
						className={({isActive})=>`column bottonIcon navbar-item ${isActive && 'ACTIVELINK'} `}
					>
						<i className="icon as-male-user-1"></i>
						<span className="labelRt">Profile</span>
					</NavLink>

					<NavLink
						to='config'
						className={({isActive})=>`column bottonIcon navbar-item ${isActive && 'ACTIVELINK'} `}
					>
						<i className="icon as-cogs"></i>
						<span className="labelRt">Conf</span>
					</NavLink>

				</div>
			</footer>	
		</>
	)
});

export default FooterApp
