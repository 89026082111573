
import { Routes, Route } from "react-router-dom";
import SocketProvider from "../context/SocketContext";
import LoginScreen from "../features/auth/LoginScreen";
import ConfigScreen from "../features/trackapp/ConfigScreen";
import MapScreen from "../features/trackapp/MapScreen";
import ReplayScreen from "../features/trackapp/ReplayScreen";
import TrackApp from "../features/trackapp/TrackApp";
import TravelScreen from "../features/trackapp/travelScreen";
import UsrCnfScreen from "../features/trackapp/UsrCnfScreen";

// import _404Screen from "../features/_pages/404Screen";
interface Props {
	
}

const MainRouterApp = (props: Props) => {
	
	return (
		<div>
			<Routes>
				<Route path="login" element={ <LoginScreen /> } />
				<Route path="/*" element={ <LoginScreen /> } />
				<Route path="track" element={ 
					<SocketProvider>
						<TrackApp /> 
					</SocketProvider>
				}  >
						<Route path="map" element={ <MapScreen /> } />
						<Route path="replay" element={ <ReplayScreen /> } />
						<Route path="travelmap" element={ <TravelScreen /> } />
						<Route path="usrinfo" element={ <UsrCnfScreen /> } />
						<Route path="config" element={ <ConfigScreen /> } />
						<Route path="*"  element={ <MapScreen /> } />
						<Route path=""  element={ <MapScreen /> } />
				</Route>
				{/* <Route path="/*" element={ <_404Screen /> } /> */}
				{/* <Route path="abouth" element={} />
				<Route path="/*" element={} /> */}
			</Routes>
		</div>
	)
}

export default MainRouterApp
