import { vehicleRT_T } from "../../_types/vehicleRTType";
import { UIActionsT, uiActonList } from "../actions/uiActions";


// definicion del 
export type  UIStateI = {
	vhPanel: boolean,
	dataLoading:boolean,
	recofinished?: string,
	vehReplayFor?: vehicleRT_T,

}

const _INIT_STATE = {
	vhPanel: false,
	dataLoading: true,
}

export const UIReducer = (state:UIStateI =_INIT_STATE, action:UIActionsT):UIStateI | undefined=>{
	switch (action.type) {
		case uiActonList.showVHPanel:			
			return {
				...state,
				vhPanel: true
			}
		case uiActonList.hideVHPanel:
			return {
				...state,
				vhPanel: false
			}
		case uiActonList.setLoading:
			return {
				...state,
				dataLoading: action.payload
			}
		case uiActonList.vehReplayFor:
			return{
				...state,
				vehReplayFor: action.payload
			}
		case uiActonList.setRecofinished:
			return{
				...state,
				recofinished: action.payload
			}
		default:
			break;
	}
	if (state) {
		return state;
	}else{
		return undefined;
	}
}



