import { useEffect, useState } from "react";
import { securedFetch } from "../../_helpers/fetchHelper";

interface Props {
	
}

const UsrCnfScreen = (props: Props) => {

	const [fleets, setFleets] = useState<any[]>([])
	function mapOpt(arg0: number, arg1: string) {
		const key = ['mapType', 'mapColor'][arg0]
		localStorage.setItem(key, arg1)
	}
	const getFleets =  ()=>{
		securedFetch('/mtrack/fleet','POST',{
				action:"getMyFleets",
			}
		).then(responce=>{
			console.log(responce.data, "<<");
			if (!responce.error) {
				if (responce.data.status === 'ok') {
					setFleets(responce.data.data)
					return;
				}else{
					
				}
			}else{
			}
		});
	}

	useEffect(() => {
		getFleets();
	}, [])
	const setFlotilla = (f:any)=>{
		try {
			securedFetch('/mtrack/user','POST',{
					action:"setFleetCia",
					refVal: {
						fleet: f.fid,
						cia: f.ciaid
					}
				}
			).then(responce=>{
				if (!responce.error) {
					if (responce.data.status === 'ok') {
						console.log(responce.data, "<<< Asignacion");
						localStorage.removeItem('APPTOKEN');
						window.location.reload();
						return;
					}else{
						
					}
				}else{
				}
			});
		} catch (error) {
			console.log(error, "Estableciendo flotillas");
		}
	}
	return (
		<div className='insidePanel'>
			<div className="title is-5">Toda las configuraciones</div>

			<div className="maptyepcont">
				<div className="column">
					<div className="">Tipo de Mapa</div>
					<a className="button is-small is-fullwidth" onClick={()=>{	mapOpt(0,'roadmap')		}}> Predeterminado </a>
					<a className="button is-small is-fullwidth" onClick={()=>{	mapOpt(0,'terrain')		}}> Terreno </a>
					<a className="button is-small is-fullwidth" onClick={()=>{	mapOpt(0,'hybrid')	}}> Satelite </a>
				</div>
				<div className="column">
					<div className="">Color</div>
					<a className="button is-small is-fullwidth" onClick={()=>{	mapOpt(1,'0');	}}> Predeterminado </a>
					<a className="button is-small is-fullwidth" onClick={()=>{	mapOpt(1,'1');	}}> Oscuro </a>
					<a className="button is-small is-fullwidth" onClick={()=>{	mapOpt(1,'2');	}}> Arena </a>
					<a className="button is-small is-fullwidth" onClick={()=>{	mapOpt(1,'3');	}}> ASMap </a>
				</div>
			</div>

			<div id="fleetlist">
				<ul>
					{
						fleets.length>1 && fleets.map(f=>{
							return <li className="fleetItem box" style={{
								borderBottom: `2px solid ${f.color}`
							}}>
								<div className="finfo">
									<div>
										{f.fname}
									</div>
									<p> {f.companititle} -- {f.shortname} </p>

								</div>
								<div 
									className="fbutton"
									onClick={()=>{setFlotilla(f)}}
								>
									<i className="as-check"></i>
								</div>
							</li>
						})
					}
				</ul>
			</div>
			
		</div>
	)
}


export default UsrCnfScreen

