import React, { LegacyRef, useState } from "react";
import { GoogleMap, InfoWindow, Marker } from "react-google-maps"
import MakeIcon from "../../_helpers/IconHelper";
import { setCenter } from "../../_store/actions/trackActions";
import { useAppDispatch, useAppSelector } from "../../_store/hooks";

interface Props {
	id: string,
	xoom: number
	map?: GoogleMap
}





const VehickeMarker = React.memo(({id, xoom}: Props) => {
	const dispatch = useAppDispatch();
	const vehiculo = useAppSelector(state => state.track?.vehicles?.find(x=>x._id === id));
	const [open, setOpen] = useState(false);


	
	let w = 15;
	if (xoom > 7) {
		w += ((xoom * 2)-7)
	}
	if (vehiculo && vehiculo.follow) {
		dispatch(setCenter(vehiculo.latitud || 0, vehiculo.longitud || 0))
	}
	return (
		<Marker
			position={{ lat: vehiculo?.latitud || 0, lng: vehiculo?.longitud || 0 }}
			key={`icon_${vehiculo?._id}`}
			onClick={()=>setOpen(s=>!s)}
			icon={MakeIcon(vehiculo?.type || 0, vehiculo?.color || undefined, vehiculo?._id || '', vehiculo?.azimut||0, w, vehiculo?.plate || '')
				
			}
		>
			{
			open && 
			<InfoWindow 
				onCloseClick={()=> setOpen(false)}
				
			>
				<>
					<h1> {vehiculo?.alias} </h1>
					<img 
						src={'/service/imgthums/veh/'+vehiculo?._id} 
						alt="No hay Imagen" 
						style={{
							width: "55vw"
						}}
					/>
				</>
			</InfoWindow>
			}
		</Marker>
	)
})

export default VehickeMarker
