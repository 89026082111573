import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SocketContext } from "../../../context/SocketContext";
import { showVHPanelAction } from "../../../_store/actions/uiActions"
import { useAppDispatch, useAppSelector } from "../../../_store/hooks"
import VHPBody from "./VHPBody";
interface Props {
	
}

const VhPanel = React.memo((props: Props) => {
	console.log("  RENDER: VHPanel");
	const dispatch = useAppDispatch();
	const {online} = useContext(SocketContext);
	const vhPanel = useAppSelector(state => state.ui?.vhPanel)
	const location = useLocation();
	
	return (
		<>
			{
				['/track/map'].indexOf(location.pathname) > -1 && (
					<div 
						id="movePanel" 
						className="hero is-info"
						onClick={()=>{
							
							dispatch(showVHPanelAction())
						}}
					>
						<i className="bottonIcon as-car"></i>
					</div>
				)
			}

			{
				['/track/map'].indexOf(location.pathname) > -1 && (
					<div 
						id="reconectButton" 
						className={
							"hero is-warning"
						}
						onClick={()=>{
							window.location.reload();
						}}
					>
						<i className={
							`bottonIcon  ${online?'as-link sconect':'as-chain-broken sdconect'}`
						}></i>
					</div>
				)
			}
			<aside id="vhPanel" className={
				`box animate__animated noselect ${(vhPanel && vhPanel===true) ?'showed animate__fadeInRight':'hidden animate__fadeOutRight'}`   
			}>
				<VHPBody />
			</aside>
		</>
	)
});

export default VhPanel
