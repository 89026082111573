import { getDate } from "./timeHelper";

export const ProcessTransmission = (data:string, tgpsOffset:Number = -6)=>{
	let parts = data.toString().split(",");
	// console.log(parts, "<<<< partes de la trasmision")

	let veh;
	if (parts[0] == "$POS") {
		veh = {
			isOnline: true,
			_id : parts[1],
			speed : Number(parts[3] || 0),
			azimut : Number(parts[4] || 0),
			longitud : Number.parseFloat(parts[5]),
			latitud : Number.parseFloat(parts[6]),
			odo : Number(parts[7] || 0),
			tgps: getDate(parts[8], false, -6)?.getTime() || 0,
			tgsm: getDate(parts[9], false, -6)?.getTime() || 0,
			stoptime : Number(parts[11].toString() || 0) || 0,
			stsmotor : parts[14] && parts[14] === '1'
		}
	}else{
		console.log(data, "<< TRANSMISION NO PROCESADA")
	}

	return veh;
}