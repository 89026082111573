import { ChangeEvent, useState } from "react"
/**
 * 
 * @param initialState Estado Inicial de los controles del formilario
 * @returns {State, inputChangeHandler} 
 */
export const useForm = 
	<T extends Object>(initialState:T)
	:{
		state:T,
		handlerInputChange:({ target }: ChangeEvent<HTMLInputElement>) => void,
		setDataForm: (data:T)=>void
	} => {
	const [state, setState] = useState<T>(initialState);

	const handlerInputChange = ({target}:ChangeEvent<HTMLInputElement>)=>{
		const {name, value} = target;

		// console.log(value, "<<< New Value");
		
		setState({
			...state,
			[name]: value
		})
	}
	const setDataForm: (data:T)=>void = (data:T)=>{
		setState(data);
	}
	return {state, handlerInputChange, setDataForm};
}