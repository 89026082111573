import { UserT } from "../../_types/userType";
import { AuthAction, authActionList } from "../actions/authActions";


// definicion del 
export type  AuthStateT = {
	user?:UserT | null,
	token?: string 
	logged?: boolean
}

// Recuperrar el estado de LocalStorage 
const ssr= localStorage.getItem('userLogged');
const ilog= localStorage.getItem('isLogged');

const _INIT_STATE = {
	user: ssr? JSON.parse(ssr) as UserT : null,
	logged: ilog? JSON.parse(ilog) : false,
}

export const authReducer = (state:AuthStateT =_INIT_STATE, action:AuthAction) : AuthStateT | undefined=>{
	switch (action.type) {
		case authActionList.login:
			return {
				...state,
				user: action.payload,
				logged: true,
			}
		case authActionList.logout:
			return {
				...state,
				user: null,
				logged: false
			}
		case authActionList.setToken:
			return{
				...state,
				token: action.payload
			}
		default:
			break;
	}

	if (state) {
		return state;
	}else{
		return undefined;
	}
}



