import { isExpired, decodeToken } from "react-jwt";
import { useAppSelector } from "../_store/hooks";
import { AuthStateT } from "../_store/reducers/authReducer";
import { UserT } from "../_types/userType";

const useValidSession = () => {
	try {
		
		
		const  auth = useAppSelector(state=>state.auth);
		const {user,token:mtoken } = (auth as AuthStateT)
		if (
			user 
			&& user.exp 
			&&  new Date(user.exp) > new Date()) {
			return {
				valid : true,
				session: user,
				token: mtoken,
			}				
		}

		const token = localStorage.getItem('APPTOKEN');
		
		const valid = token && !isExpired(token);

		if (!valid) {
			localStorage.removeItem('APPTOKEN');
			console.log(token, "<<<< TOKEN DELETE");	
		}

		const session =	token &&  (decodeToken(token) as UserT);

		return {
			valid,
			session,
			token,
		}
	} catch (error) {
		return {
			valid:false,
			session: {},
			token: null
		}
	}

}

export default useValidSession
