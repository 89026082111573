import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, InfoWindow, Marker } from "react-google-maps";
import credential from "../../credentials/credential";
import { securedFetch } from "../../_helpers/fetchHelper";
import { clientIcon } from "../../_helpers/IconHelper";
import { useAppSelector } from "../../_store/hooks";
import { ClientPoint } from "../../_types/ClientType";
import GMap from "../componens/gmaps/map";
import VehickeMarker from "../componens/vehickeMarker";

interface Props {
	
}



const MapScreen = React.memo((props: Props) => {
	console.log("RENDER MAPScreen 🤒 🤒");
	const _mapCenter = JSON.parse(localStorage.getItem('mapCenter')!)
	const mapZoom = Number(localStorage.getItem('mapZoom') || 10)
	const mapType = (localStorage.getItem('mapType') || 'roadmap');
	const mapColor = localStorage.getItem('mapColor') || '0';

	const susbVeh = useAppSelector(state => state.track?.subscribers);
	const setCenter = useAppSelector(state=> state.track?.setCenter);
	const [mapCenter, setMapCenter] = useState(_mapCenter || {
		lat: 15.296524,
		lng:  -88.478687
	});

	const _map = useRef(null);
	const [Xoom, setXoom] = useState(mapZoom);
	const [map, setMap] = useState<GoogleMap | null>(null)
	
	useEffect(() => {
		console.log(setCenter,'setCenter, Recibido');
		
		if (setCenter) {
			setMapCenter({
				lat: setCenter[0],
				lng:  setCenter[1]
			} )
		}
	}, [setCenter])




	// Proceso para la creacion d puntos de clietes
	// 🌟 🌟 🌟 🌟 🌟 🌟
	const boundsTime = useRef<NodeJS.Timeout | null>(null)
	const [clientes, setClientes] = useState<ClientPoint[] | null>([])
	const [currClient, setCurrClient] = useState<ClientPoint | null>(null)
	const setBoundary = (frame:google.maps.LatLngBounds)=>{
		if (boundsTime.current) {
			clearTimeout(boundsTime.current);
		}
		if (Xoom >15) {
			boundsTime.current = setTimeout(()=>{
				let bounds = [
					[frame.getNorthEast().lng(), frame.getNorthEast().lat()],
					[frame.getSouthWest().lng(), frame.getNorthEast().lat()],
					[frame.getSouthWest().lng(), frame.getSouthWest().lat()],
					[frame.getNorthEast().lng(), frame.getSouthWest().lat()],
					[frame.getNorthEast().lng(), frame.getNorthEast().lat()]
				]	
				getClientsBy(bounds as any);
			}, 1100)
		}else{
			if (clientes && clientes.length> 0) {
				setClientes([]);
			}
		}
	}
	const getClientsBy= (bounds: Float32Array[][])=>{
		try{
			if (bounds && bounds.length> 0) {
				securedFetch('/mtrack/customer','POST',{
					action: "queryBoundsMobil",
					boundFilter:bounds,
				}).then(responce=>{
					if (responce.data.status == 'ok') {			
						setClientes((x)=>{
							if (x && x.length>0) {
								return [
									...x,
									...responce.data.data.filter((y:any) => x?.findIndex(z=>z._id === y._id ) === -1).map((y:any)=>{
										return {
											_id : y._id,
											nick : y.nick,
											latitud : y.point.coordinates[1],
											longitud : y.point.coordinates[0],
											depname : y.depname,
											towshipname : y.towshipname,
											city : y.city,
											neighborhood : y.neighborhood,
										}
									})
								];
								
							}else{
								return responce.data.data.map((y:any)=>{
									return {
										_id : y._id,
										nick : y.nick,
										latitud : y.point.coordinates[1],
										longitud : y.point.coordinates[0],
										depname : y.depname,
										towshipname : y.towshipname,
										city : y.city,
										neighborhood : y.neighborhood
									}
								}) 
							}
							return x;
						})	
					} 
					console.log(responce.data, "Customers DATA!!! 🧂 🧂 🧂");
										
				});

			}
		}catch(err){}
	}
	// 🌟 🌟 🌟 🌟 🌟 🌟
	


	return (
		<GMap
			center={mapCenter || {
				lat: 15.296524,
				lng:  -88.478687
			}}
			zoom={mapZoom}
			color = {mapColor}
			typeMap = {mapType}
			
			containerElement={
				<div style={{
					height:'100%',
					width:'100%'
				}} /> 
			}
			setBoundary={setBoundary}
			ref={_map.current}
			mapElement = {
				<div style={{
					height:'100%'
				}} />
			}
			setZoom={setXoom}
			googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${credential.GMApi}&libraries=geometry,drawing,places`}
			loadingElement={<p>Obteniendo Mapas....</p>}	
		>
			{susbVeh?.map((m,i)=>{
				return <VehickeMarker key={m} id={m} xoom={Xoom} />
			})}

			{
				clientes && clientes.length>0 && clientes.map(c=>{
					return <Marker
						zIndex={1}
						position={{ lat: c.latitud || 0, lng: c.longitud || 0 }}
						key={`clientIcon_${c._id}`}
						icon={clientIcon()}
						onClick={()=>{
							setCurrClient(c);
						}}
					/>
				})
			}

		
			{
				currClient && <InfoWindow 
					onCloseClick={()=> setCurrClient(null)}
					options={{
						position: new google.maps.LatLng(currClient.latitud, currClient.longitud)
					}}
				>
				<>
					<h1> {currClient.nick}</h1>
					<img 
						src={'/service/imgthums/cus/'+ currClient._id} 
						alt="No hay Imagen" 
						style={{
							width: "55vw"
						}}
					/>
					<h1> {currClient.towshipname}</h1>
					<h1> {currClient.city}</h1>
					<h1> {currClient.neighborhood}</h1>

				</>
				</InfoWindow>
			}
			
		</GMap>
	)
});

export default MapScreen