import { FleetT } from "../../_types/fleetType";
import { POSTransmision } from "../../_types/gpsComunicatorType";
import { vehicleRT_T } from "../../_types/vehicleRTType";

//Todas las posibles acciones del auth reducer 
export enum trackActionsList {
	setFleetInfo='[TRACK] setFleetInfo',
	noneOne= "[TRACK] NO HACE NADA",
	setVehiclesList='[TRACK] setVehiclesList',
	setTrackingVh='[TRACK] setTrackingVh',
	sortVh='[TRACK] sortVh',
	setCurrentVh='[TRACK] setCurrentVh',
	setFollowVh='[TRACK] setFollowVh',
	newTransmision = "[TRACK] new Trasnmision",
	setCenter = "[TRACK] SET CENTER",
}

// firma de las acciones del Auth Reducer
export type TrackActionsT =
  | { type: trackActionsList.setFleetInfo, payload: FleetT  | any }
  | { type: trackActionsList.setVehiclesList, payload:vehicleRT_T[]}
  | { type: trackActionsList.setTrackingVh, payload:{ vh: string, state: boolean }}
  | { type: trackActionsList.setCurrentVh, payload:{ vh: string, state: boolean }}
  | { type: trackActionsList.setFollowVh, payload:{ vh: string, state: boolean }}
  | { type: trackActionsList.newTransmision, payload: POSTransmision } 
  | { type: trackActionsList.setCenter, payload: [number, number] } 
  | { type: trackActionsList.sortVh } 
  | { type: trackActionsList.noneOne};


export const setFleetInfoAction = (fleet:FleetT | null ):TrackActionsT=>({
	type: trackActionsList.setFleetInfo,
	payload: fleet
});

export const setVhListAction = (vehicles:vehicleRT_T[]) : TrackActionsT=>{
	return {
		type: trackActionsList.setVehiclesList,
		payload: vehicles
	}
};

export const setTrackingVhAction = (vehicleId: string, tracking: boolean) : TrackActionsT=>{
	return {
		type: trackActionsList.setTrackingVh,
		payload: {
			vh: vehicleId,
			state: tracking
		}
	}
};
export const setCurrentVhAction = (vehicleId: string, tracking: boolean) : TrackActionsT=>{
	return {
		type: trackActionsList.setCurrentVh,
		payload: {
			vh: vehicleId,
			state: tracking
		}
	}
};
export const setFollowVhAction = (vehicleId: string, tracking: boolean) : TrackActionsT=>{
	return {
		type: trackActionsList.setFollowVh,
		payload: {
			vh: vehicleId,
			state: tracking
		}
	}
};
export const setCenter = (latitud: number,  longitud:number) : TrackActionsT=>{
	return {
		type: trackActionsList.setCenter,
		payload: [latitud,longitud]
	}
};

export const shorVehicles = () : TrackActionsT=>{
	return {
		type: trackActionsList.sortVh
	}
};


export const asyncTrackingVhAction = async (vehicleId: string, tracking: boolean)=>{	
	return async (_dispatch:any)=>{
		try {

		} catch (error) {
			console.log(error, "<< No se puede asigna mas de 5 vehiculos");
		}
	}
}


export const newTransmision =  (trasm:POSTransmision): TrackActionsT => {
	return {
		type: trackActionsList.newTransmision,
		payload: trasm
	}
}


