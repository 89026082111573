import React from 'react'

interface Props {
	
}

const TopLogo = (props: Props) => {
	return (
		<span className='box topLogoBox'>
			<img  className='logoImage' src="/assets/images/logo250.png" alt="AllSmartt.com"/>	
		</span>
	)
}

export default TopLogo
