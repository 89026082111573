import React, { LegacyRef, useState } from "react";
import { GoogleMap, InfoWindow, Marker } from "react-google-maps"
import MakeIcon, { stopIcon } from "../../_helpers/IconHelper";
import { StopTime } from "../../_helpers/timeHelper";
import { setCenter } from "../../_store/actions/trackActions";
import { useAppDispatch, useAppSelector } from "../../_store/hooks";

interface Props {
	xoom: number,
	lat: number,
	long: number,
	lasting: number,
	finit: number,
	ffin:number,
	map?: GoogleMap
}
const StopMarker = React.memo(({xoom, lat, long, lasting, ffin, finit}: Props) => {
	const dispatch = useAppDispatch();
	const [open, setOpen] = useState(false);	
	// console.log(lat, long, lasting, ffin, finit, "<<< INFO DEL PARO");
	let w = 5;
	if (xoom > 7) {
		w += ((xoom * 1.2)-7)
	}
	return (
		<Marker
			zIndex={50}
			position={{ lat: lat || 0, lng: long || 0 }}
			key={`icon_${finit}`}
			onClick={()=>setOpen(s=>!s)}
			icon={stopIcon(w)}
		>
			{
			open && 
			<InfoWindow 
				key={`infow_${finit}`}
				onCloseClick={()=> setOpen(false)}
				
			>
				<>
					<h1> {StopTime(lasting)} </h1>
					<h1> Inicio: {new Date(finit).toLocaleString()} </h1>
					<h1> Fin: {new Date(ffin).toLocaleString()} </h1>
					
				</>
			</InfoWindow>
			}
		</Marker>
	)
})

export default StopMarker
