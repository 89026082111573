import React, { useCallback, useEffect, useRef, useState } from "react";
import { GoogleMap, InfoWindow, Marker, Polyline } from "react-google-maps";
import credential from "../../credentials/credential";
import GMap from "../componens/gmaps/map";
import ReplayScreen from "./ReplayScreen";
import Swal from "sweetalert2";  
import StopMarker from "../componens/StopMarker";
import { clientIcon, finIcon, initIcon } from "../../_helpers/IconHelper";
import { ClientPoint } from "../../_types/ClientType";
import { securedFetch } from "../../_helpers/fetchHelper";

interface Props {
	
}

type InfoTrasn={
    lat: number;
    long: number;
    speed: number;
    time: number;
    stime: number;
}
const TravelScreen = React.memo((props: Props) => {
	console.log("RENDER TravelScreen 🤒 🤒");
	const mapZoom = Number(localStorage.getItem('mapZoom') || 10)
	const mapType = (localStorage.getItem('mapType') || 'roadmap');
	const mapColor = localStorage.getItem('mapColor') || '0';
	const RECO = localStorage.getItem('reco');

	const [reload, setReload] = useState(0);
	const _map = useRef(null);
	const [Xoom, setXoom] = useState(mapZoom);
	const [map, setMap] = useState<GoogleMap | null>(null)
	const [travel, setTravel] = useState<any>(null);
	const [LinesPoly, setLinesPoly] = useState<JSX.Element[]>([]);

	const [mapCenter, setMapCenter] = useState<any>(null);
	// === Para la Info Windows de trasnmisiones!
	const [showInfo, setShowInfo] = useState(false)
	const [InfoWDate, setInfoWDate] = useState<InfoTrasn>({
		lat: 0,
		long: 0,
		speed: 0,
		time:0,
		stime: 0,
	})
	// === Creacion de recorrido
	useEffect(() => {
		if (RECO) {
			setTravel(JSON.parse(RECO));
			setTravel((state:  any)=>{
				state.stops = (state.stops as any[] ).filter(p=>p.l>10);
				return state;
			});
		}
	}, []);


	useEffect(() => {
		console.log("Pide el recodido");
		if (travel) {
			if (travel.reco[0]) {
				setMapCenter({
					lat: travel.reco[0].lt || 0, 
					lng: travel.reco[0].ln || 0
				});
			}else{
				setMapCenter({
					lat: 15.296524,
					lng:  -88.478687
				});
			}
			try {
				if (travel.reco.length >1) {
					let totTrans = travel.reco.length;
					setLinesPoly((travel.reco as any[]).reduce((prev, val, index, array)=>{
						if (index> 0 && index < totTrans -1) {
							let color = '';
							if (Number(val.s) >= 70) {
								color = "#770581";
							} else if (Number(val.s) <= 5) {
								color = "#8888a8";
							} else {
								color = "#6868ff";
							};

							
							prev.push(<Polyline
								key={val.gt+index}
								
								options={{
									zIndex: 4,
									path:[
										{ lat: val.lt, lng: val.ln },
										{ lat: array[index-1].lt, lng: array[index-1].ln }
									],
									icons: [{
										icon: {
											path: google.maps.SymbolPath.BACKWARD_OPEN_ARROW,
											strokeColor: "#000",
											fillColor: color,
											fillOpacity: 0.4,
											strokeOpacity: 0.6,
											scale: 2
										},
										offset: '50%'
									}],
									strokeColor: color,
									strokeOpacity: 1.0,
									strokeWeight: 3
								}}
								onClick={()=>{
									console.log(val);
									setInfoWDate({
										lat: val.lt,
										long: val.ln,
										speed: val.s,
										time: val.gt,
										stime: val.srt,
									});
									setShowInfo(true);
									
								}}
							/>);
						}
						return prev;
					}, []));
				}
			} catch (error) {}
		}

	}, [travel]);


	// Proceso para la creacion d puntos de clietes
	// 🌟 🌟 🌟 🌟 🌟 🌟
	const boundsTime = useRef<NodeJS.Timeout | null>(null)
	const [clientes, setClientes] = useState<ClientPoint[] | null>([])
	const [currClient, setCurrClient] = useState<ClientPoint | null>(null)
	const setBoundary = (frame:google.maps.LatLngBounds)=>{
		if (boundsTime.current) {
			clearTimeout(boundsTime.current);
		}
		if (Xoom >15) {
			boundsTime.current = setTimeout(()=>{
				let bounds = [
					[frame.getNorthEast().lng(), frame.getNorthEast().lat()],
					[frame.getSouthWest().lng(), frame.getNorthEast().lat()],
					[frame.getSouthWest().lng(), frame.getSouthWest().lat()],
					[frame.getNorthEast().lng(), frame.getSouthWest().lat()],
					[frame.getNorthEast().lng(), frame.getNorthEast().lat()]
				]	
				getClientsBy(bounds as any);
			}, 1100)
		}else{
			if (clientes && clientes.length> 0) {
				setClientes([]);
			}
		}
	}
	const getClientsBy= (bounds: Float32Array[][])=>{
		try{
			if (bounds && bounds.length> 0) {
				securedFetch('/mtrack/customer','POST',{
					action: "queryBoundsMobil",
					boundFilter:bounds,
				}).then(responce=>{
					if (responce.data.status == 'ok') {			
						setClientes((x)=>{
							if (x && x.length>0) {
								return [
									...x,
									...responce.data.data.filter((y:any) => x?.findIndex(z=>z._id === y._id ) === -1).map((y:any)=>{
										return {
											_id : y._id,
											nick : y.nick,
											latitud : y.point.coordinates[1],
											longitud : y.point.coordinates[0],
											depname : y.depname,
											towshipname : y.towshipname,
											city : y.city,
											neighborhood : y.neighborhood,
										}
									})
								];
								
							}else{
								return responce.data.data.map((y:any)=>{
									return {
										_id : y._id,
										nick : y.nick,
										latitud : y.point.coordinates[1],
										longitud : y.point.coordinates[0],
										depname : y.depname,
										towshipname : y.towshipname,
										city : y.city,
										neighborhood : y.neighborhood
									}
								}) 
							}
							return x;
						})	
					} 
					console.log(responce.data, "Customers DATA!!! 🧂 🧂 🧂");
										
				});

			}
		}catch(err){}
	}
	// 🌟 🌟 🌟 🌟 🌟 🌟


	if (!RECO) {
		return <ReplayScreen/>
	}
	const infoTravel = async ()=>{
		const resp = await Swal.fire({
			title: "TravelInfo",
			customClass:{
				htmlContainer:"swalInfoTravel"
			},
			text: `Vehiculo: ${travel.alias} \n
Placa: ${travel.data.plate} \n
KMs: ${travel.data.totKms.toFixed(2)}\n
V.Prom: ${(travel.data.velAvg || 0).toFixed(2)} K/H \n
V.Max: ${travel.data.velMax.toFixed(2)} K/H \n
Paros: ${travel.stops.length}`,
			denyButtonText:"Eliminar",
			showDenyButton: true,
			reverseButtons:true,

		})
		console.log(resp, "<< Asi dijo!!");
		if (resp && resp.isDenied && resp.isDenied === true ) {
			localStorage.removeItem('reco');
			setReload(x=>x+1);
		}
	}
	

	
	return (
		<>
			<GMap
				refMap={setMap}
				center={mapCenter || {
					lat: 15.296524,
					lng:  -88.478687
				}}
				zoom={mapZoom}
				color = {mapColor}
				typeMap = {mapType}
				containerElement={
					<div style={{
						height:'100%',
						width:'100%'
					}} /> 
				}
				setBoundary={setBoundary}
				ref={_map.current}
				mapElement = {
					<div style={{
						height:'100%'
					}} /> 
				}
				setZoom={setXoom}
				googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${credential.GMApi}&libraries=geometry,drawing,places`}
				loadingElement={<p>Obteniendo Mapas....</p>}	
			>
				
				
				{
					LinesPoly.map((l=>{
						return l;
					}))
				}

				{
					travel && travel.stops && (travel.stops as any[]  || []).map((val, index, array)=>{
						return <StopMarker
							key={val.fi + index + val.l}
							xoom={Xoom}
							ffin={val.ff}
							finit={val.fi}
							lasting={val.l}
							lat={val.lt}
							long={val.ln}
						/>
					})
				}


				{
					showInfo&& <InfoWindow 
						onCloseClick={()=> setShowInfo(false)}
						options={{
							position: new google.maps.LatLng(InfoWDate.lat, InfoWDate.long)
						}}
					>
					<>
						<h1> {InfoWDate.speed} Km/h</h1>
						<h1> 🕑 GPS:{new Date(InfoWDate.time).toLocaleTimeString()} </h1>
						<h1> 🕑 SRV:{new Date(InfoWDate.stime).toLocaleTimeString()} </h1>
						<img 
							src={'http://tracker.grupoamancio.com/service/imgthums/veh/'+ travel._id} 
							alt="No hay Imagen" 
							style={{
								width: "55vw"
							}}
						/>
					</>
					</InfoWindow>
				}

				{
					map && LinesPoly.length>0 &&  (
						<>
							<Marker
								zIndex={1}
								position={{ lat: travel.reco[0].lt || 0, lng: travel.reco[0].ln || 0 }}
								key={`INICIO_RECO`}
								icon={initIcon(35)}
							/>
							<Marker
								zIndex={1}
								position={{ lat: travel.reco[travel.reco.length -1].lt || 0, lng: travel.reco[travel.reco.length -1].ln || 0 }}
								key={`FIN_RECO`}
								icon={finIcon(35)}
							/>
						</>
					)
				}
				{
					clientes && clientes.length>0 && clientes.map(c=>{
						return <Marker
							zIndex={1}
							position={{ lat: c.latitud || 0, lng: c.longitud || 0 }}
							key={`clientIcon_${c._id}`}
							icon={clientIcon()}
							onClick={()=>{
								setCurrClient(c);
							}}
						/>
					})
				}
				{
					currClient && <InfoWindow 
						onCloseClick={()=> setCurrClient(null)}
						options={{
							position: new google.maps.LatLng(currClient.latitud, currClient.longitud)
						}}
					>
					<>
						<h1> {currClient.nick}</h1>
						<img 
							src={'/service/imgthums/cus/'+ currClient._id} 
							alt="No hay Imagen" 
							style={{
								width: "55vw"
							}}
						/>
						<h1> {currClient.towshipname}</h1>
						<h1> {currClient.city}</h1>
						<h1> {currClient.neighborhood}</h1>

					</>
					</InfoWindow>
				}
			</GMap>
			<div
				onClick={infoTravel} 
				className="infobottom"
			>
				<i className="as-info" />
			</div>
		</>
	)
});

export default TravelScreen