import { vehicleRT_T } from "../../_types/vehicleRTType";

//Todas las posibles acciones del auth reducer 
export enum uiActonList {
	showVHPanel='[UI] Mostrar VHPanel',
	hideVHPanel='[UI] OcultarVHPanel',
	setLoading='[AUTH] setLoading',
	vehReplayFor='[UI] setVehReplay',
	setRecofinished='[UI] Recorrido Finalizado!',
}

// firma de las acciones del Auth Reducer
export type UIActionsT =
  | { type: uiActonList.showVHPanel }
  | { type: uiActonList.setLoading, payload: boolean }
  | { type: uiActonList.vehReplayFor, payload: vehicleRT_T }
  | { type: uiActonList.setRecofinished, payload?: string}
  | { type: uiActonList.hideVHPanel };

export const showVHPanelAction = ():UIActionsT=>({
	type: uiActonList.showVHPanel
})

export const hideVHPanel = ():UIActionsT=>({
	type: uiActonList.hideVHPanel
})

export const setVehReplayForAction = (veh:vehicleRT_T):UIActionsT=>({
	type: uiActonList.vehReplayFor,
	payload: veh,
})

export const setLoadingAction = (loading:boolean):UIActionsT=>({
	type: uiActonList.setLoading,
	payload: loading
});

export const setRecofinishedAction = (id:string | undefined):UIActionsT=>({
	type: uiActonList.setRecofinished,
	payload: id

});