
import { asyncLoginAction } from '../../_store/actions/authActions';
import { FormEvent } from 'react';
import Base64 from 'crypto-js/enc-base64';
import sha512 from 'crypto-js/sha512';
import { Navigate } from "react-router-dom";
import { useAppDispatch } from '../../_store/hooks';


import { useForm } from '../../_hooks/useForm'
import TopLogo from '../componens/TopLogo'
import useValidSession from '../../_hooks/useValidSession';


interface Props {
	
}
interface FormDataI{
	user:string,
	password: string,
}

const LoginScreen = (props: Props) => {
	const {valid} = useValidSession();
	const dispatch = useAppDispatch();
	
	const {state: frmData, handlerInputChange, /*setDataForm*/ } =useForm<FormDataI>({
		user: '',
		password:''
	});
	if (valid) {
		return <Navigate to={'/track/map'}/>;
	}

	const {user, password} = frmData;


	const submit =async (e:FormEvent<HTMLFormElement>)=>{
		e && e.preventDefault();
		dispatch(await asyncLoginAction(user,Base64.stringify(sha512(password))));
	}

	return (
		<>
			<div className="columns is-mobile m-1">
				<div className="column  is-full is-narrow">
					< TopLogo />
					<form className="box pr-5 pl-5 mtv-20" onSubmit={submit}>
						<div className="field">
							<label className="label">Usuario</label>
							<div className="control has-icons-right">
								<input 
									className="input" 
									type="text" 
									placeholder="e.g. Luis@allsmartt" 
									autoComplete='off'
									name='user'
									value = {user}
									onChange={handlerInputChange}
								/>
								<span className='icon is-small is-right'>
									<i className={ 
										'as-user ' + [
											(user.length > 0 && user.length <5) ? 'tred20d':'',
											user.length > 4 &&  'tgreen',
										].join(' ')
									}></i>
								</span>
							</div>
						</div>
						<div className="field">
							<label className="label">Password</label>
							<div className="control has-icons-right">
								<input 
									className="input" 
									type="password"
									autoComplete='off' 
									placeholder="&bull;&bull;&bull;&bull;&bull;&bull;" 
									name='password'
									value = {password}
									onChange={handlerInputChange}
								/>

								<span className='icon is-small is-right'>
									<i className={
										'as-key '+ [
											(password.length > 0 && password.length <5) ? 'tred20d':'',
											password.length > 4 &&  'tgreen',
										].join(' ')
									}></i>
								</span>
							</div>
						</div>
						<button className="button is-info">Login</button>
					</form>
				</div>
			</div>
		</>
	)
}

export default LoginScreen
