import React from 'react'
import { useNavigate } from 'react-router-dom';
import { StopTime } from '../../../_helpers/timeHelper';
import { setCenter, setCurrentVhAction, setFollowVhAction, setTrackingVhAction } from '../../../_store/actions/trackActions';
import { hideVHPanel, setVehReplayForAction } from '../../../_store/actions/uiActions';
import { useAppDispatch } from '../../../_store/hooks';
import { vehicleRT_T } from '../../../_types/vehicleRTType'

import './vhlistitem.scss'

interface Props {
	vehicle: vehicleRT_T
}

const VHListItem = React.memo(({vehicle}: Props) => {
	const dispatch = useAppDispatch();
	const changeSelected = ()=>{
		dispatch(setTrackingVhAction(vehicle._id, !vehicle.tracking));
		if (vehicle.follow) {
			dispatch(setFollowVhAction(vehicle._id, false));
		}
	}
	const navigate = useNavigate();
	const seeClick = ()=>{
		if (vehicle.tracking && vehicle.latitud && vehicle.longitud) {
			console.log("Fabor Centrar!!!", vehicle.latitud);
			dispatch(setCenter(vehicle.latitud, vehicle.longitud));
		}
	}
	const setCurrent = ()=>{
		dispatch(setCurrentVhAction(vehicle._id, !vehicle.current));
	}
	const setFollow = ()=>{
		vehicle.tracking &&  dispatch(setFollowVhAction(vehicle._id, !vehicle.follow));
	}
	const replayFor = ()=>{
		dispatch(setVehReplayForAction(vehicle));
		dispatch(hideVHPanel());
		navigate("/track/replay")
	}


	const _ut = vehicle.svrtime? new Date(vehicle.svrtime) : null
	let ut = "";
	if (_ut) {
		let parts = _ut.toISOString();
		let _d = parts.split('T')[0].split('-');
		ut = [
			_d[2],
			_d[1],
			_d[0].substring(2),
			' ',
			parts.split('T')[1].split(':').slice(0,2).join(':'),
		].join('')
	}
	return (
		<>
			<li className='vhitem-block'>
				<div
					onClick={changeSelected} 
					className="stretchItem pointer"
				>
					<i className={
						`as-globe ${vehicle.tracking? 'trackin':''}`
					}></i>
				</div>
				<div
					onClick={seeClick}
					className="fillitem pointer"
				>
					<div className="aliasname" style={
						{
							"color":vehicle.tracking && vehicle.online?'#daa145':'' 
						}
					}>
						{vehicle.alias}
					</div>
					<div className="namePlate">
						{vehicle.plate}  -  {vehicle.name}
					</div>
					{
						vehicle.tracking && (
							<div className="namePlate">
								Km/H: {vehicle.speed ? (
										<>
											{vehicle.speed} 
											<i className="as-right-dir  infoicons">
												
											</i>
										</>
									
									) 
									: '--'  
								}  
								{vehicle.stoptime?  StopTime(vehicle.stoptime || 0):'' }
								|| UT" { ut }
							</div>
						)
					} 
				</div>
				<div
					className="stretchItem pointer paramicon"
					onClick={ setCurrent }
					style={{
						borderRight: `6px solid ${vehicle.color}`,
						paddingRight: '2px',
					}}
				>
					<i className={
						`as-params ${vehicle.current? 'trackin':''}`
					}></i>
				</div>
			</li>
			{
				vehicle.current === true && (
					<li className='vhitem-block'>
						<div 
							className="bottomicons"
							onClick={setFollow}
						>
							<i className={
								`as-location-arrow ${vehicle.follow?'trackin':''}`
							}></i>
							<span className="labelRt">Follow</span>
						</div>
						<div 
							className="bottomicons"
							onClick={replayFor}
						>
							<i className="as-globe"></i>
							<span className="labelRt">Replay</span>
						</div>
						<div className="bottomicons">
							<i className="icon as-info"></i>
							<span className="labelRt">Info</span>
						</div>
					</li>
				)		
			}
		</>
		

	)
});

export default VHListItem
