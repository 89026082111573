import { isDate } from "util";

export const StopTime = ( seconds : number):string=>{

	if (!seconds || seconds == 0) {
		return '--'
	}

	if (seconds < 60) {
		return `${seconds} Seg`
	}

	const tdias = 24*60*60;
	const thoras = 60*60;
	const tmin = 60;

	let dias = 0;
	let horas = 0;
	let min =0;
	let res= seconds;

	if (res > tdias ) {
		dias = Math.floor(res / tdias);
		res = res % tdias;
	}

	if (res > thoras ) {
		horas = Math.floor(res / thoras);
		res = res % thoras;
	}

	if (res > tmin ) {
		min = Math.floor(res / tmin);
		res = res % tmin;
	}
	
	let paro = [
		dias>0?`${dias}D `:'',
		horas>0?` ${horas}H`:'',
		min>0?` ${min}M `:'',
	].join('')
	return paro
} 

export const getDate = (date: string, print = false, offset = 0)=>{
	print && console.log(date);

	if (new Date(date).toString() !== 'Invalid Date' ) {
		const _date = new Date(date);
		_date.setTime( _date.getTime() + (offset  * 60 * 60 * 1000) )
		return _date ;

	}
	return new Date();

	// let parts = date.split("T")
	// let datepart = parts[0]
	// let timepart;
	// if (parts[1].indexOf(".") != -1) {
	// 	timepart = parts[1].split(".")[0]
	// } else if (parts[1].indexOf("-") != -1) {
	// 	timepart = parts[1].split("-")[0]
	// } else if (parts[1].indexOf("Z") != -1) {
	// 	timepart = parts[1].slice(0, -1)
	// } else {
	// 	console.log("Error Procesando ⛽️", date);
	// 	timepart = "00:00:00"
	// }
	// let _date = new Date(datepart + " " + timepart)
	// _date.setTime(_date.getTime() + (offset * 2 * 60 * 60 * 1000))
}